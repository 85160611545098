<template>
  <div id="app" data-app>
    <div
      role="alert"
      v-show="errors.length > 0"
      v-bind:class="{ show: errors.length }"
      class="alert fade alert-danger"
    >
      <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ error }}
      </div>
    </div>
    <v-app>
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="primary accent-4"
          centered
          dark
          icons-and-text
        >
          <v-tab href="#subscribe" @click="getResultsbytype(1)">
            User Details

            <v-icon>mdi-account</v-icon>
          </v-tab>
          <v-tab href="#contact" @click="getResultsbytype(2)">
            Upload Results

            <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="tab-item-wrapper">
          <v-tab-item :key="1" value="subscribe">
            <v-card flat>
              <v-card-text>
                <v-col class="text-right">
                  <v-btn
                    color="error"
                    class="mb-2 font-weight-bold"
                    @click="CreateUser"
                  >
                    New User
                  </v-btn>
                </v-col>
                <v-data-table
                  :headers="headers"
                  :items="labs"
                  class="elevation-1"
                  :page="labpage"
                  :loading="loading"
                  :pageCount="labnumberOfPages"
                  :server-items-length="labtotal"
                  :options.sync="laboptions"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 50],
                    'items-per-page-text': '',
                  }"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editUser(item)">
                      mdi-pencil
                    </v-icon>

                    <v-icon small @click="deleteUser(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2" value="contact">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3" md="1"> </v-col>

                  <v-col
                    cols="12"
                    sm="1"
                    class="text-right filter_label pt-4"
                    v-if="!this.isMobile"
                  >
                    From
                  </v-col>

                  <v-col
                    cols="12"
                    sm="1"
                    class="text-left filter_label"
                    v-if="this.isMobile"
                  >
                    From
                  </v-col>

                  <v-col cols="12" sm="3" md="2">
                    <date-picker
                      v-model="fromDate"
                      placeholder="From Date"
                      :config="dateoptions"
                    ></date-picker>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="1"
                    class="text-right filter_label pt-4"
                    v-if="!this.isMobile"
                  >
                    To
                  </v-col>

                  <v-col
                    cols="12"
                    sm="1"
                    class="text-left filter_label"
                    v-if="this.isMobile"
                  >
                    To
                  </v-col>

                  <v-col cols="12" sm="3" md="2">
                    <date-picker
                      v-model="ToDate"
                      placeholder="To Date"
                      :config="dateoptions"
                    ></date-picker>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="1"
                    class="text-right filter_label pt-4"
                    v-if="!this.isMobile"
                  >
                    Search
                  </v-col>
                  <v-col
                    cols="12"
                    sm="1"
                    class="text-left filter_label"
                    v-if="this.isMobile"
                  >
                    Search
                  </v-col>
                  <v-col cols="12" sm="3" md="2">
                    <b-input-group>
                      <b-form-input
                        placeholder="Search"
                        id="result_search"
                        v-model="search"
                      ></b-form-input>
                    </b-input-group>
                  </v-col>
                  <v-col class="text-right" cols="12" sm="3" md="2">
                    <v-btn
                      color="error"
                      class="mb-2 font-weight-bold"
                      @click="UploadFile"
                    >
                      Upload Results
                    </v-btn>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="resultheaders"
                  :items="rads"
                  class="elevation-1"
                  :search="search"
                  :page="radpage"
                  :loading="loading"
                  :pageCount="radnumberOfPages"
                  :server-items-length="radtotal"
                  :options.sync="radoptions"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 50],
                    'items-per-page-text': '',
                  }"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.PatientName="{ item }">
                    <div class="d-flex justify-left">
                      {{ item.PatientName }}
                    </div>
                  </template>
                  <template v-slot:item.ContactNo="{ item }">
                    <div class="d-flex justify-left">{{ item.ContactNo }}</div>
                  </template>
                  <template v-slot:item.TestName="{ item }">
                    <div class="d-flex justify-left">{{ item.TestName }}</div>
                  </template>
                  <template v-slot:item.PatientName="{ item }">
                    <div class="d-flex justify-left">
                      {{ item.PatientName }}
                    </div>
                  </template>
                  <template v-slot:item.uploadedBy="{ item }">
                    <div class="d-flex justify-left">{{ item.uploadedBy }}</div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editResult(item)">
                      mdi-pencil
                    </v-icon>

                    <v-icon small class="mr-2" @click="deleteResult(item)">
                      mdi-delete
                    </v-icon>

                    <a
                      href="javascript:void(0);"
                      @click="showresult(item.testid)"
                      v-if="item.result != 0"
                    >
                      <v-icon class="mr-2" small color="gray darken-2">
                        mdi-eye
                      </v-icon>
                    </a>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="3" value="patdetails">
            <v-card flat>
              <v-card-text>
                <div class="container">
                  <div class="row m-2 mt-5">
                    <div class="col-md-2 patient-details-labels">MRN:</div>
                    <div class="col-md patient-details-values">
                      {{ mrnno }}

                      <v-divider></v-divider>
                    </div>
                  </div>
                  <div class="row m-2 mt-0">
                    <div class="col-md-2 patient-details-labels">
                      Patient Name:
                    </div>
                    <div class="col-md patient-details-values">
                      {{ patname }}

                      <v-divider></v-divider>
                    </div>
                  </div>
                  <div class="row m-2 mt-0">
                    <div class="col-md-2 patient-details-labels">
                      Age/Gender:
                    </div>
                    <div class="col-md patient-details-values">
                      {{ agegender }}

                      <v-divider></v-divider>
                    </div>
                  </div>
                  <div class="row m-2">
                    <div class="col-md-2 patient-details-labels">
                      Date of Birth:
                    </div>
                    <div class="col-md patient-details-values">
                      {{ dob }}
                      <v-divider></v-divider>
                    </div>
                  </div>
                  <div class="row m-2 mt-0">
                    <div class="col-md-2 patient-details-labels">Mobile:</div>
                    <div class="col-md patient-details-values">
                      {{ cellphone }}
                      <v-divider></v-divider>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-app>
  </div>
</template>
<style>
.redcolor {
  background: #f55246 !important;
  color: white !important;
}

.orangecolor {
  background: #ffa014 !important;
  color: white !important;
}

.greencolor {
  background: #5abf5a !important;
  color: white !important;
}

.v-data-table th {
  font-size: 1rem !important;
}

.v-data-table td {
  font-size: 0.9rem !important;
}

.v-data-footer {
  font-size: 1rem !important;
}

.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 0.9rem !important;
}

.patient-details-labels {
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.patient-details-values {
  font-size: 1rem !important;
}

  /*.tab-item-wrapper {
  vuetify sets the v-tabs__container height to 48px 
  height: calc(100vh - 48px);
}*/

.v-btn::before {
  background-color: #4152d8 !important;
}

.v-text-field input {
  font-size: 0.9em;
}

.v-btn--active::before {
  opacity: 0.8 !important;
}

.filter_label{
   font-size: 1.03rem !important;
   color: rgba(0, 0, 0, 0.6) !important;
   font-weight: 600;
}

.v-card{
  box-shadow:none !important;
}

</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import axios from "axios";
import JwtService from "@/common/jwt.service";
import { ROO_API_URL, API_URL } from "@/common/config";
import { mapGetters } from "vuex";
//import "bootstrap/dist/css/bootstrap.css";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import moment from "moment";

Vue.use(datePicker);

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      fromDate: moment(new Date()).add(-60, "days").format("DD/MM/YYYY"),
      ToDate: moment(new Date()).format("DD/MM/YYYY"),
      menu: false,
      modal: false,
      menu2: false,
      isMobile: false,
      tab: "subscribe",
      istabchanged: false,
      mrnno: "",
      patname: "",
      agegender: "",
      dob: "",
      cellphone: "",
      search: "",
      tabIndex: 0,
      tabIndex2: 0,
      labpage: 1,
      labtotal: 0,
      labnumberOfPages: 0,
      laboptions: {},
      loading: true,
      labs: [],
      rads: [],
      radpage: 1,
      radtotal: 0,
      radnumberOfPages: 0,
      radoptions: {},
      selected: [],
      singleSelect: false,
      expanded: [],
      errors: [],
      dateoptions: {
        format: "DD/MM/YYYY",
        useCurrent: false,
        showClear: false,
        showClose: false,
      },
      resultheaders: [
        {
          text: "Requisition No",
          value: "LabNo",
          sortable: false,
          align: "center",
        },
        {
          text: "Invoice No",
          value: "InvoiceNo",
          sortable: false,
          align: "center",
        },

        {
          text: "Patient Name",
          value: "PatientName",
          sortable: false,
          align: "center",
        },
        {
          text: "Contact No",
          value: "ContactNo",
          sortable: false,
          align: "center",
        },
        { text: "Test", value: "TestName", sortable: false, align: "center" },
        {
          text: "Uploaded By",
          value: "uploadedBy",
          sortable: false,
          align: "center",
        },
        {
          text: "Uploaded Date",
          value: "uploadedDate",
          sortable: false,
          align: "center",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers: [
        {
          text: "User Name",
          value: "userName",
          sortable: false,
          align: "Left",
        },
        {
          text: "First Name",
          value: "firstName",
          sortable: false,
          align: "Left",
        },
        {
          text: "Last Name",
          value: "lastName",
          sortable: false,
          align: "Left",
        },
        { text: "Email", value: "email", sortable: false, align: "Left" },
        { text: "Mobile", value: "mobileNo", sortable: false, align: "Left" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);

    const isAdmin = JwtService.getToken().split("~")[3];

    if (isAdmin == undefined || isAdmin == 0)
      this.$router.push({ name: "adminlogin" });
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"]),
    computedDateFormatted() {
      console.log("computedDateFormatted " + this.date);
      return this.formatDate(this.date);
    },
    computedToDateFormatted() {
      console.log("computedToDateFormatted " + this.date);
      return this.formatDate(this.date);
    },
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.params.tab != null) this.tab = this.$route.params.tab;
    this.onResize();
  },
  watch: {
    laboptions: {
      handler() {
        this.getUserDetails();
      },
    },
    radoptions: {
      handler() {
        this.getUploadedResults();
      },
    },
    search() {
      this.getUploadedResults();
    },
    deep: true,
    fromDate() {
      this.getUploadedResults();
    },
    ToDate() {
      this.getUploadedResults();
    },
  },
  methods: {
    showresult(value) {
      let querydata = "39cd7-49t" + value + "-264aad22f46f" + JwtService.getFacilityToken().split("~")[1];
      let routeData = this.$router.resolve({
        name: "ViewResults",
        query: { key: querydata },
      });
      window.open(routeData.href, "_blank");
      console.log(value);
    },
    getUserDetails() {
      if (JwtService.getToken()) {
        const ID = JwtService.getToken().split("~")[1];
        //console.log(this.$route.query);
        const mrn = ID.split("^")[0];
        const cellphone = ID.split("^")[1];
        //console.log("mrn " + mrn + " celphone " + cellphone);
        this.loading = true;
        const { page, itemsPerPage } = this.laboptions;
        //console.log("Page Number ", page, itemsPerPage, this.search);

        const data = {
          username: mrn,
          password: cellphone,
          pageSize: itemsPerPage,
          page: page,
          facilityid: JwtService.getFacilityToken().split("~")[0],
        };

        axios
          .post(ROO_API_URL + "UserDetails", data)
          .then((response) => {
            this.loading = false;
            this.labs = response.data;
            this.labtotal = response.data.count;
          })
          .catch((response) => (this.errors = [response]));
      } else {
        this.$router.push({ name: "login" });
      }
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    },
    getUploadedResults() {
      if (JwtService.getToken()) {
        const ID = JwtService.getToken().split("~")[1];
        //console.log(this.$route.query);

        //console.log(ID);
        const mrn = ID.split("^")[0];
        const cellphone = ID.split("^")[1];

        //console.log("mrn " + mrn + " celphone " + cellphone);

        this.loading = true;
        const { page, itemsPerPage } = this.radoptions;
        console.log("Page Number ", page, itemsPerPage, this.search);

        console.log(this.fromDate + " " + this.ToDate);

        var fdate = moment(this.fromDate, "DD/MM/YYYY").toDate();
        var tdate = moment(this.ToDate, "DD/MM/YYYY").toDate();

        const data = {
          username: mrn,
          password: cellphone,
          pageSize: itemsPerPage,
          page: page,
          facilityid: JwtService.getFacilityToken().split("~")[1],
          search: this.search,
          From: moment(fdate).format("YYYY-MM-DD"),
          To: moment(tdate).format("YYYY-MM-DD"),
        };

        console.log(data);
        axios
          .post(API_URL + "getOtherManualResult", data)
          .then((response) => {
            this.loading = false;
            this.rads = response.data.data;
            this.radtotal = response.data.count;
            console.log(response.data);
          })
          .catch((response) => (this.errors = [response]));
      } else {
        this.$router.push({ name: "login" });
      }
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    },
    getColor(NormalRangeColor) {
      console.log(NormalRangeColor);
      if (NormalRangeColor == "Pending") return "redcolor";
      else if (NormalRangeColor == "Pending") return "orangecolor";
      else return "greencolor";
    },
    getResultsbytype(id) {
      console.log(id);
      this.istabchanged = true;
    },
    onResize() {
      console.log("resioze");
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    myEventHandler(e) {
      console.log(e);
      this.onResize();
      console.log("is mobile " + this.isMobile);
    },
    CreateUser() {
      this.$router.push({ name: "User" });
    },
    editUser(item) {
      console.log(item);
      this.$router.push({ name: "User", params: { userobj: item } });
    },
    deleteUser(data) {
      axios
        .post(ROO_API_URL + "DeleteUser", { ID: data.id })
        .then((response) => {
          console.log(response.data);
          this.getUserDetails();
        })
        .catch((response) => (this.errors = [response]));
    },
    editResult(item) {
      console.log(item);
      this.$router.push({ name: "UploadResults", params: { resultobj: item } });
    },
    deleteResult(data) {
      axios
        .post(API_URL + "deleteManualResult", { id: data.id })
        .then((response) => {
          console.log(response.data);
          this.getUploadedResults();
        })
        .catch((response) => (this.errors = [response]));
    },
    UploadFile() {
      this.$router.push({ name: "UploadResults" });
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getDate() {
      const toTwoDigits = (num) => (num < 10 ? "0" + num : num);
      let today = new Date();
      let year = today.getFullYear();
      let month = toTwoDigits(today.getMonth() + 1);
      let day = toTwoDigits(today.getDate());
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
